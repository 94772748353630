@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{

    .navContainer{
        @apply fixed top-0 w-full flex flex-col justify-between items-center px-[5vw] py-3  sm:flex-row  bg-[#f7f7f7] drop-shadow-md
    }

    .navItem{
        @apply uppercase text-sm font-serif font-extralight w-full h-[15vh] sm:h-fit grid place-content-center hover:underline
    }
    .activeNavItem{
        @apply underline
    }
    .logo{
        @apply text-[40px] font-serif
    }

    .techsContainer{
        @apply grid place-content-center
    }

    .techIconContainer{
        @apply bg-black h-[90px] aspect-square grid place-content-center p-1 rounded-xl hover:animate-wiggle-it text-white uppercase text-[10px] gap-2 text-center drop-shadow-xl
    }

    .techIcon{
        @apply h-[35px] bg-black mx-auto
    }
    .contactItemsContainer{
        @apply flex gap-1
    }
    .contactInfoItem{
        @apply flex items-center gap-2 text-xs p-2 w-fit h-fit mx-auto overflow-hidden animate-glide-in-bottom-200
    }
    .contactIcon{
        @apply h-[30px]
    }
    .emailerContainer{
        @apply relative border-2 border-black flex flex-col  text-left gap-2 text-xs sm:text-lg m-auto w-[80vw] font-bold max-w-[570px] px-[20px] py-[20px]
    }
    .emailerInput{
        @apply bg-[#f7f7f7] border-2 border-black p-1 font-light 
    }

}